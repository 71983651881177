<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenu></sideMenu>
              </div>
              <div class="col-lg-9">
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'UpdateVehicles',
                                      params: {
                                        url_key: VehicleDetails.vehId,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  /></router-link>

                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                      :to="{
                                        name: 'UpdateVehicles',
                                        params: {
                                          url_key: VehicleDetails.vehId,
                                        },
                                      }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                    <!-- <div class="row" style="margin: 0px">
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Odometer</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.kmClocked }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-map-marker pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Location</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.locName }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fas fa-car pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Trim Level</span>
                                            <p class="fontWeightbidlist">
                                              Base
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Disclosure</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fa fa-fire pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Mileage</span>
                                            <p class="fontWeightbidlist">
                                              Not Actual
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>As Is</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div> -->
                                    <hr style="margin: 0px" />
                                    <div class="row">
                                      <div class="col-6">
                                        <div class="text-center">
                                          <div>
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              Park
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div class="text-center"></div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>Bid Information</h4>
                  <p>
                    Brand :<span>{{ auctionEdit.brandName }}</span>
                  </p>
                  <p>
                    Model :<span>{{ auctionEdit.modelName }}</span>
                  </p>
                  <p>
                    Year of Make Amount :<span>{{ auctionEdit.manYear }}</span>
                  </p>
                  <p>
                    VIN No :<span>{{ auctionEdit.vehRegNo }}</span>
                  </p>
                  <p>
                    Latest Bid Amount :<span>{{ auctionEdit.vehPrice }}</span>
                  </p>
                  <div class="row">
                 
                    <div class="col-6">
                      <div>
                        <button class="bid-info-sub" @click="openModel = false">
                          Cose
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="bid-info-sub" @click="SubmitBid()">
                          Park
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="message == 'Success'">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="message">
                    <div class="alert alert-warning" role="alert">
                      {{ message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenu from "@/components/sideMenu.vue";
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  components: {
    sideMenu,
    PageNation,
    NotFound,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      auctionEdit: "",
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.customerId != "undefined") {
        this.getpendingSellerAuctions(this.userinfo.customerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    SubmitBid() {
      this.message = "Successfully parked";
      var self = this;
      setTimeout(function () {
        self.closeModel();
      }, 2000);
    },
    closeModel() {
      this.message = "";
      this.openModel = false;
    },
    reAuction(e) {
      this.auctionEdit = e;
      this.openModel = !this.openModel;
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    getSellerLostAuctionsList(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getSellerAuctionsStatus(id, "Lost")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
            this.vechApproval = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.overlay-bid-btn {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(226 240 248);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
